
























































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component({})
export default class Login extends Vue {
  email = "";
  password = "";
  checkbox_remember_me = false;

  async login() {
    this.$vs.loading();
    try {
      await this.$store.dispatch("auth/login", { email: this.email, password: this.password });
    } catch (error) {
      this.$vs.notify({
        title: 'Erro',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      });
      console.error(error);
    }
    this.$vs.loading.close();
  }
}
